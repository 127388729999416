<template>
  <!-- 公开公示 -->
  <div class="notice-container">
    <div class="banner-size">
      <div class="banner-limit banner-info">
        <!-- <span class="banner-fMax">通知公告</span>
        <span class="banner-fMin banner-other">让政府扶持像网购一样便捷</span> -->
      </div>
    </div>

    <div
      class="banner-limit public-announcement mt-20 mb-20"
      ref="noticeContainer"
    >
      <PublicForm class="mt-20" :lists="dataList" :itemType="2"></PublicForm>
      <el-pagination
        style="margin: 20px auto"
        background
        hide-on-single-page
        :total="dataList.length"
        :page-size="pageSize"
        @current-change="changePageNum"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import PublicForm from "@/components/publicForm"; // 公开公示表单
import { Loading } from "element-ui";

export default {
  components: {
    PublicForm,
  },
  data() {
    return {
      // 公告列表数据
      dataList: [],
      pageSize: 10,
    };
  },
  mounted() {
    this.getPublicList(1);
  },
  methods: {
    changePageNum(index){
      this.getPublicList(index);
    },
    getPublicList(pageNum) {
      let loadingNoticeContainer = Loading.service({
        target: this.$refs.noticeContainer,
      });
      // type 1项目政策 2通知公告 3公开公示
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        type: 2,
      };
      // console.log("params:", params);
      this.dataList = [];
      this.$httpApi
        .get(`/dev-api/document/list`, params)
        .then((res) => {
          console.log("通知公告列表:", res);
          if (res.code === 200) {
            loadingNoticeContainer.close();
            this.dataList = res.rows;
          }
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/com/theme.scss";

.notice-container {
  // height: 762px;
  .banner-size {
    background: url("../../assets/images/icon_banner_declare.png");
  }

  .banner-info {
    // width: 100%;
    height: 100%;
    display: flex;
    // justify-content: flex-start;
    align-items: center;

    .banner-fMax {
      font-size: 40px;
    }

    .banner-other {
      margin-left: 20px;
      font-size: 20px;
    }
  }

  .public-announcement {
    // background: red;
    // width: 100%;
    // height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }
}

/* 标题 */
.el-table__header-wrapper {
  .is-leaf {
    background: $bc-lightGrey;
  }
}

/* 内容 */
.el-table__body-wrapper {
  /* 状态栏 */
  // .el-table_1_column_1 {
  //     color: $color-danger !important;
  // }
  /* 横向属性 */
  .el-table__row {
    // background-color: #333;
    height: 60px;
    cursor: pointer;

    &:hover {
      // height: 70px;
      // font-size: $font-size18;
      font-weight: bold;

      .el-table_1_column_2 {
        color: $color-primary !important;
      }
    }
  }
}

.text-red {
  color: $color-danger;
}

/* 分页器 */
.el-pagination {
  // background-color: red;
  margin-top: 40px;
  align-self: flex-end;
}
</style>
