<template>
  <el-table :data="lists" style="width: 100%" @row-click="todo">
    <el-table-column label="序号" width="120" align="center">
      <template slot-scope="scope">
        <div>{{ scope.$index + 1 }}</div>
      </template>
    </el-table-column>
    <el-table-column prop="name" label="标题" min-width="540" align="left">
    </el-table-column>
    <el-table-column prop="deptname" label="发布部门" width="200" align="center">
    </el-table-column>
    <el-table-column
      prop="policypubdate"
      label="公示时间"
      width="220"
      align="center"
    >
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: "publicForm",
  props: {
    lists: {
      type: Array,
      default: function () {
        return [];
      },
    },
    //关注需要传此类型
    itemType: {
      type: Number,
      default: function () {
        return 0;
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    todo(val) {
      let { href } = this.$router.resolve({
        path: "/notice/detail/" + val.id + "?type=" + this.itemType,
      });
      window.open(href, "_blank");
    },
    /* 暴露组件方法 */
  },
};
</script>

<style lang="scss">
@import "@/styles/com/theme.scss";
/* 标题 */
.el-table__header-wrapper {
  .is-leaf {
    background: $bc-lightGrey;
  }
}
/* 内容 */
.el-table__body-wrapper {
  /* 状态栏 */
  // .el-table_1_column_1 {
  //     color: $color-danger !important;
  // }
  /* 横向属性 */
  .el-table__row {
    height: 60px;
    cursor: pointer;
    &:hover {
      // height: 70px;
      // font-size: $font-size18;
      font-weight: bold;
      .el-table_1_column_2 {
        color: $color-primary !important;
      }
    }
  }
}
.text-red {
  color: $color-danger;
}
</style>
